import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import RolexCategoryBtns from '../../components/preowned/RolexCategoryBtns'

// markup
const RolexDay = () => {
  const data = useStaticQuery(
    graphql`
      query queryRolexDay {
        products: allStrapiProduct(
          filter: { brand: { eq: "Rolex" }, model: { regex: "/Day-Date/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Rolex Day Date Watches for Sale'}
      canonical={'/fine-watches/rolex/rolex-day-date-president/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Rolex Day Date Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/rolex/rolex-day-date-president/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-rolex-daydate-header.png"
                alt="Pre-Owned Certified Used Rolex Day-Date Watches Header"
                aria-label="Used Rolex Day-Date Watch Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              ROLEX DAY-DATE: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED ROLEX WATCHES
            </h1>
            <h2>BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED ROLEX DAY-DATE WATCHES</h2>
            <p>
              <br />
              Pre-Owned Used Rolex Day-Date Watch
              <br />
              <br />
              Now in age, the pre-owned used Rolex watch market has flourished to highs no one
              could've imagined. To buy one directly from an authorized certified Rolex seller would
              require dealing with a long waiting standing between you and your unused Rolex
              Daytona, Batman, Day-Date 40, Hulk, steel Sky-Dweller or 18k Yellow Gold, 18k Rose
              “Everose” or White Gold.
              <br />
              <br />
              Lucky for consumers around the nation, Gray and Sons Jewelers is the #1 Used and
              Pre-owned Rolex seller and buyer in the country. Gray &amp; Sons also repair all fine
              swiss watches – repairs for Rolex Day-Date, President or Presidential and more ..{' '}
              <a href="/">www.grayandsons.com</a> also polishes, details, repairs, restores, sells,
              a nd installs Rolex bracelets and links for the Day Date Rolex with the oyster link,
              President, and jubilee links.
              <br />
              <br />
              <b>Buying and Selling Pre-Owned Used Rolex Day-Date Watches</b>
              <br />
              <br />
              With a large in stock inventory of used pre-owned certified Rolex models, we
              can provide buyers with the Rolex wristwatch they desire. From sports models such as
              the Rolex Submariner, Yacht-Master, or Daytona to dress-up pieces such as the Rolex
              Datejust or Day-Date; we have it all.
              <br />
              <br />
              Now more than ever, we have several used certified pre-owned or unused
              Rolex Day-Date Presidential watches in platinum, Yellow gold, White gold, and Everose.
              We carry pre-owned 36mm Day-Date President timepieces and wristwatches, along with
              modern 40mm and 41mm calibers as well. Every watch in our store is guaranteed
              certified and comes with our very own 2-year Gray and Sons Jewelers
              warranty, with 6 in-house jewelers and watchmakers and $1 million in parts and Rolex
              genuine and custom accessories like diamond Rolex bezels, mother of pearl Rolex dials
              with and without diamonds, and much more. visit our boutique showroom across from Bal
              Harbour shops in Miami Beach surfside.
              <br />
              <br />
              Gray &amp; Sons is an independent Rolex dealer, which means better inventory control
              and more price flexibility for customers. Every preowned Rolex Day-Date watch for sale
              on Gray &amp; Sons’ website is in stock, ready to ship. Our decision-makers are ready
              to make deals on any in-stock secondhand Explorer men’s watch – or any other used
              Rolex watch available.
              <br />
              <br />
              Conversely, if you’ve wondered, “Where can I sell my used Rolex Day-Date watch near
              me?” you can get paid the best cash offer by visiting{' '}
              <a href="https://sellusyourjewelry.com/" target="_blank">
                SellUsYourJewelry.com
              </a>
              <br />
              <br />
              <b>
                Gray &amp; Sons is the Best Source for Buying and Selling Pre-Owned Rolex Day-Date
                Watches
              </b>
              <br />
              <br />
              <a href="/">Gray &amp; Sons</a> is proud to have served thousands of happy customers
              over the last 40 years at both our physical store, as well as our leading pre-owned
              watch e-commerce website. Many customers have nominated Gray &amp; Sons as the number
              one buyer and seller of pre-owned used Rolex Day-Date watches. Chat with an expert
              today. Click <a href="/chat-with-rich">here to talk with a Rolex Specialist</a> today.
              <br />
              <br />
              The Gray &amp; Sons store (located adjacent to the Bal Harbour Shops in
              Surfside/Miami, open six days a week) serves the Miami, Miami Beach, Coral Gables,
              Coconut Grove, South Beach, Bal Harbour, Surfside, Sunny Isles, Ventura, Fort
              Lauderdale, Pinecrest, and Brickle areas. Whether in-store or through the website,
              Gray &amp; Sons watch experts are always ready to help you buy or sell secondhand
              Rolex Day-Date watches. Plus, we also have a Gray &amp; Sons independent Rolex
              Day-Date repair facility for any watch maintenance needs.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/catalogsearch/result?q=day+date">
                <button>SHOP ROLEX DAY-DATE WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK ROLEX DAY-DATE WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <section>
        <h2 className="text-center py-10">EXPLORE MORE GREAT WATCH STORIES FROM GRAY &amp; SONS</h2>
        <RolexCategoryBtns />
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default RolexDay
